import { Helmet } from 'react-helmet-async'

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  )
}

Meta.defaultProps = {
  title: '株式会社815',
  description:
    '⼦どもだって、⼤⼈だって、「勇気」を持って「⾏動」し、「⾃由」を⼿に⼊れよう。「情熱」と「好奇⼼」を持ち「リーダーシップ」を持って⾃分の⼈⽣を彩るために頑張る人をサポートするのが株式会社815です。',
  keywords: '株式会社815, ECCジュニアBS幕張ベイタウン教室, 幕張 ECCジュニア',
}

export default Meta
