import { Link } from 'react-router-dom'
import { FaInstagramSquare } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { FaYoutube } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import { FaLine } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className="footer w-full flex flex-col items-center justify-between px-10 pt-20 pb-14 bg-[#F7F4F0]">
      <div className="container flex flex-col items-center lg:flex-row justify-between mb-20 md:mb-40">
        <nav className="flex flex-col md:flex-row items-center justify-center gap-10 mb-10">
          {/* <div className="menu-list">
            <Link to="/academy">815 アカデミー</Link>
          </div> */}
          <div className="menu-list">
            <Link to="/ecc-junior">ECCジュニアBS幕張ベイタウン教室</Link>
          </div>

          <div className="menu-list">
            <Link to="/company">会社案内</Link>
          </div>
          <div className="menu-list">
            <Link to="/contact">お問い合わせ</Link>
          </div>
          <div className="menu-list">
            <Link
              to="https://note.com/happy_144/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ブログ
            </Link>
          </div>
        </nav>
        <nav className="mb-10 order-first md:order-none">
          <div className="flex items-center gap-5 text-2xl mb-5">
            <Link
              to="https://www.instagram.com/eccmakuharibaytown?igsh=bWgxM2hkc3FqOHBu&utm_source=qr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/Instagram_Glyph_Gradient.svg"
                className="w-7"
                alt="インスタグラム"
              />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=100063583807132"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/Facebook_Logo_Primary.png"
                className="w-7"
                alt="フェイスブック"
              />
            </Link>
            <Link
              to="https://youtube.com/@eccbs1243?si=eivwEWlzJl20oHHO"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/yt_logo_rgb_light.png"
                className="w-24"
                alt="YouTube"
              />
            </Link>
            <Link
              to="https://x.com/ecc_happy"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/logo-black.png" className="w-6" alt="X" />
            </Link>
            <div className="lg:tooltip" data-tip="ECCジュニア公式サイトへ">
              <Link
                to="https://www.eccjr.co.jp/mail_question/map_detail/map.php?id=Y29tbW9uX2lk&room_cd=MTIxOTAySw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-32"
                  src="images/ecc-junior-logo.svg"
                  alt="ECCジュニア"
                />
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <aside className="container flex flex-col lg:flex-row lg:justify-between items-center lg:items-start w-full text-left">
        <div className="flex flex-col mb-5 md:mb-0 md:flex-row items-center md:items-start text-left">
          <Link to="/">
            <img
              className="w-16 mb-5 md:mr-7"
              src="/images/815logo_4c.png"
              alt="株式会社815"
            />
          </Link>
          <p className="text-center pt-2">
            © 2024 815 Co.,Ltd. All right reserved.
          </p>
        </div>
        <p className="text-center">
          このウェブサイトはECCジュニアの公式サイトとは関係ありません
        </p>
      </aside>
    </footer>
  )
}

export default Footer
