import { Link, NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { menuChangeState } from '../store/slices/menuSlice'

const Header = () => {
  const { isOpen } = useSelector((state) => state.menu)
  const dispatch = useDispatch()

  const changeMenuStateHandler = () => {
    dispatch(menuChangeState())
  }
  return (
    <header className="flex-none flex items-center justify-between h-24 md:h-28 w-full max-w-screen-2xl px-[1rem] md:px-[2rem] mb-[1rem] md:mb-[2rem]">
      <div className="flex-1">
        <Link to="/">
          <img
            className="w-16"
            src="/images/815logo_4c.png"
            alt="株式会社815"
          />
        </Link>
      </div>
      <label className="absolute top-8 right-5 z-20 md:hidden swap swap-rotate">
        {/* this hidden checkbox controls the state */}
        <input
          type="checkbox"
          onChange={changeMenuStateHandler}
          checked={isOpen}
        />

        {/* hamburger icon */}
        <svg
          className="swap-off fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
        >
          <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
        </svg>

        {/* close icon */}
        <svg
          className="swap-on fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 512 512"
        >
          <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
        </svg>
      </label>
      <div className="hidden md:block md:flex-none">
        <ul className="flex gap-7 items-center">
          {/* <li className="menu-list">
            <NavLink
              to="/academy"
              className={({ isActive, isPending }) =>
                isPending ? 'pending' : isActive ? 'active' : ''
              }
            >
              815 アカデミー
            </NavLink>
          </li> */}
          <li className="menu-list">
            <NavLink
              to="/ecc-junior"
              className={({ isActive, isPending }) =>
                isPending ? 'pending' : isActive ? 'active' : ''
              }
            >
              ECCジュニアBS幕張ベイタウン教室
            </NavLink>
          </li>
          <li className="menu-list md:hidden lg:block">
            <NavLink
              to="/company"
              className={({ isActive, isPending }) =>
                isPending ? 'pending' : isActive ? 'active' : ''
              }
            >
              会社案内
            </NavLink>
          </li>
          <li className="menu-list md:hidden lg:block">
            <NavLink
              to="/contact"
              className={({ isActive, isPending }) =>
                isPending ? 'pending' : isActive ? 'active' : ''
              }
            >
              お問い合わせ
            </NavLink>
          </li>
          <li className="menu-list md:hidden lg:block">
            <Link
              to="https://note.com/happy_144/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ブログ
            </Link>
          </li>
        </ul>
      </div>
    </header>
  )
}

export default Header
