import Meta from '../components/Meta'

const Company = () => {
  return (
    <div className="w-full mb-20">
      <Meta title="株式会社815 | 会社概要" />
      <div className="w-full flex flex-col lg:flex-row h-[400px] lg:h-[500px] mb-10 lg:mb-28">
        <div className="order-1 lg:order-first basis-1/3 bg-sky-300 flex flex-col items-center justify-center">
          <h2 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-white border-b border-white pb-2 mb-3 lg:mb-5 inline-block">
            Company
          </h2>
          <h3 className="text-sm md:text-base lg:text-lg bg-white py-1 rounded-full w-28 lg:w-48 text-center">
            会社案内
          </h3>
        </div>
        <div className="flex-1 bg-office-image bg-cover bg-center"></div>
      </div>

      <div className="container">
        <div className="w-full mb-20">
          <div>
            <h3 className="text-sm border border-neutral-500 mb-5 lg:mb-7 inline-block py-1 px-5">
              代表の挨拶
            </h3>
            <h2 className="text-2xl mb-7">
              子どもが憧れる大人があふれる社会を。
            </h2>
            <p className="text-base/7">
              <div className="w-full md:w-80 md:float-right md:ml-10 mb-10">
                <img className="w-full" src="/images/ohki_photo.jpg" alt="" />
                <p className="text-sm text-left mt-2">
                  代表取締役社長：大木 幸子
                </p>
              </div>
              <p className="mb-2">
                弊社ウェブサイトをご覧いただきありがとうございます。
              </p>
              <p className="mb-2">
                株式会社815は、子どもにも大人にも、「楽しく」「明るい」未来を！を合言葉に、日々教育活動に力を入れております。
              </p>

              <p className="mb-2">『私には夢があります』</p>

              <p className="mb-2">
                夢や目標に向かって学び続ける子どもたちが、自分らしく、自由で安心な気持ちでいることができる世界を作ること。そして、その場所を提供する大人たちを励まし続けること。子どもたちに教育を提供する大人は、人間味にあふれ、魅力的、そして最後まで目の前の学ぶ人たちを諦めない人と信じています。
              </p>
              <p className="mb-2">
                「あんな大人になりたい」「あの人のような人生を生きていきたい」と思ってもらえる大人が増える。そして、その大人を見て子どもたちが勇気を持って世界に羽ばたいてくことができる。そのような輪が無限に、大きく広がっていくことが私の夢であります。
              </p>
              <p className="mb-2">
                株式会社815は、誰もが「勇気」を持って「行動」し、いつも「情熱」と「好奇心」をもち、自分の人生を「リーダーシップ」を持って舵取りしていくことができると信じ、みなさまにワンランク上の学びと成長をみなさまに提供してまいります。そして、弊社アソシエイツ一同は日々学びを実践し、未来を創るプロとして真心を込めてみなさまにサービスを提供してまいります。
              </p>
              <p>今後とも株式会社815をどうぞよろしくお願いいたします。</p>
            </p>
          </div>
        </div>

        <h3 className="text-center border-b border-neutral-500 pb-3 mb-10 text-xl">
          会社ヒストリー
        </h3>
        <div className="flex w-full gap-10 mb-10 lg:mb-20">
          <div className="hidden md:block bg-sky-100 flex-1"></div>
          <div className="md:basis-4/6">
            <h4 className="float-start mr-7 text-6xl font-bold mb-5 text-neutral-400">
              2001
            </h4>
            <p className="history">
              2001年4月1日、千葉市美浜区の幕張ベイタウンでECCジュニア幕張ベイタウン教室を開校。幕張ベイタウンのマンション集会室2カ所でレッスンスタート。
            </p>
            <p className="history">
              その生徒数を伸ばし、3年目には総生徒数100名を超える。2008年、中学生クラスをスタート。教室開設10周年の翌年、2011年高校生クラス以上のBS
              コース開講、教室名を「ECC
              ジュニアBS幕張ベイタウン教室」と改称。同年大人クラスも開講。中学生のスピーチコンテスト指導や資格取得試験も含め、英語教育にさらに注力する。
            </p>
            <p className="history">
              2016年6月16日、パティオス18番街テナントに教室を開設、集会室3カ所でのレッスンをテナントヘ集約。2017年、講師2名、事務1名で生徒数200名を達成。教室WEBサイト開設。
            </p>
            <p className="history">
              ECC
              ジュニアの広報において教室が取り上げられ、日経新聞『丸の内キャリア塾』インタビュー記事掲載•生徒募集コマーシャルに教室講師と生徒が出演・ECC
              ジュニアホームティーチャー募集動画「生徒からの特別授業」出演。
            </p>
            <p className="history">
              2019年、ECCジュニアサミット・トップ10教室に選出、2022年には総生徒数が全国1位となる。2024年現在6年連続全国トップ10教室、3年連続総生徒数全国1位。
            </p>
          </div>
        </div>

        <div className="flex w-full gap-10 mb-14 md:mb-28">
          <div className="md:basis-4/6">
            <h4 className="float-start mr-7 text-6xl font-bold mb-5 text-sky-500">
              2023
            </h4>
            <p className="history">
              2023年8月4日、株式会社815を設立。ECCジュニアBS幕張ベイタウン教室にて引き続き英語教育事業を推進、加えて各種コンサルティング業務・コーチング等による人材育成、教育研修事業・セミナーの企画と運営にも携わる。
            </p>
            <p className="history">
              「子どもにも大人にも楽しく、明るい未来を」を理念とし、最終的に子ども達に明るい未来が届く教育世界を目指し、子ども教育に関わる事業へ力を注ぐ企業として価値提供している。
            </p>
          </div>
          <div className="hidden md:block flex-1 bg-sky-100"></div>
        </div>

        <h3 className="text-center border-b border-neutral-500 pb-3 mb-10 text-xl">
          会社概要
        </h3>
        <div className="flex flex-col lg:flex-row w-full gap-10 items-center">
          <div className="basis-1/2">
            <div className="overflow-x-auto">
              <table className="company table">
                <tbody>
                  <tr>
                    <th>会社名</th>
                    <td>株式会社815</td>
                  </tr>
                  <tr>
                    <th>代表者</th>
                    <td>
                      <span className="text-sm mr-3">代表取締役社長</span> 大木
                      幸子
                    </td>
                  </tr>
                  <tr>
                    <th>所在地</th>
                    <td>〒261-0013 千葉県千葉市美浜区打瀬2丁目17 店舗3</td>
                  </tr>
                  <tr>
                    <th>電話番号</th>
                    <td>043-301-4739</td>
                  </tr>
                  <tr>
                    <th>設立</th>
                    <td>令和５年８月</td>
                  </tr>
                  <tr>
                    <th>事業内容</th>
                    <td>
                      <ul className="list-disc pl-[1rem]">
                        <li>英語教室の運営</li>
                        <li>各種資格・検定に向けた教室の経営</li>
                        <li>学習教材及び出版物の制作及び販売</li>
                        <li>通信教育事業</li>
                        <li>セミナーの企画、立案及び運営</li>
                        <li>コーチング等による人材育成、教育研修事業</li>
                        <li>各種コンサルティング業務</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <iframe
            className="lg:basis-1/2 w-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.527301178508!2d140.04086967617758!3d35.63937807260077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6022834122fea879%3A0x26641ae4258f7a9a!2z44CSMjYxLTAwMTMg5Y2D6JGJ55yM5Y2D6JGJ5biC576O5rWc5Yy65omT54Cs77yS5LiB55uu77yR77yXIOODkeODhuOCo-OCquOCue-8ke-8mOeVquihlw!5e0!3m2!1sja!2sjp!4v1713612473431!5m2!1sja!2sjp"
            height="450"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default Company
