import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import App from './App'
import { store } from './store'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'

// screens
import Home from './screens/Home'
import Academy from './screens/Academy'
import EccJunior from './screens/EccJunior'
import Company from './screens/Company'
import Contact from './screens/Contact'
import EccContact from './screens/EccContact'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/academy',
        element: <Academy />,
      },
      {
        path: '/ecc-junior',
        element: <EccJunior />,
      },
      {
        path: '/company',
        element: <Company />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: '/ecc-contact',
        element: <EccContact />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
)
