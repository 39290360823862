import { useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { menuChangeState } from '../store/slices/menuSlice'
import { gsap } from 'gsap'

const Menu = () => {
  const { isOpen } = useSelector((state) => state.menu)
  const comp = useRef()
  const ctx = useRef()
  const tl = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    ctx.current = gsap.context(() => {
      tl.current = gsap.timeline({ pause: true })
      tl.current
        .to('.slide-menu', {
          xPercent: 100,
          duration: 0.3,
          ease: 'power2.inOut',
        })
        .fromTo(
          '.menu-contents',
          { xPercent: -100, opacity: 0 },
          { xPercent: 0, opacity: 1, stagger: -0.1 }
        )
    }, comp)
  }, [ctx])

  useEffect(() => {
    ctx.current.add(() => {
      isOpen ? tl.current.play() : tl.current.reverse()
    })
    isOpen
      ? (document.body.style.position = 'fixed')
      : (document.body.style.position = 'static')
  }, [isOpen])

  const menuHandler = () => {
    dispatch(menuChangeState())
  }

  return (
    <div ref={comp}>
      <div className="slide-menu w-full h-screen backdrop-blur-3xl bg-white/50 z-10 absolute top-0 -left-full flex flex-col items-center justify-items-center">
        <ul className="flex flex-col gap-14 items-center justify-center h-full">
          <li className="menu-contents text-xl" onClick={menuHandler}>
            <Link to="/">トップページ</Link>
          </li>
          <li className="menu-contents text-xl" onClick={menuHandler}>
            <Link to="/ecc-junior">ECCジュニアBS幕張ベイタウン教室</Link>
          </li>
          <li className="menu-contents text-xl" onClick={menuHandler}>
            <Link to="/company">会社概要</Link>
          </li>
          <li className="menu-contents text-xl" onClick={menuHandler}>
            <Link to="/contact">お問い合わせ</Link>
          </li>
          <li className="menu-contents text-xl" onClick={menuHandler}>
            <Link
              to="https://note.com/happy_144/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ブログ
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Menu
