import { Outlet, ScrollRestoration } from 'react-router-dom'
import Header from './components/Header'
import Menu from './components/Menu'
import Footer from './components/Footer'

const App = () => {
  return (
    <div className="w-full min-h-screen flex flex-col items-center">
      <div className="w-full max-w-screen-2xl flex flex-col items-center">
        <Menu />
        <Header />
        <Outlet />
      </div>
      <Footer />
      <ScrollRestoration />
    </div>
  )
}

export default App
