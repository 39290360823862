import { configureStore } from '@reduxjs/toolkit'
import firstAnimationReducer from './slices/firstAnimationSlice'
import menuReducer from './slices/menuSlice'

export const store = configureStore({
  reducer: {
    fisrtAnimation: firstAnimationReducer,
    menu: menuReducer,
  },
})
