import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
}

export const menuSlice = createSlice({
  name: 'firstAniamtion',
  initialState,
  reducers: {
    menuChangeState: (state) => {
      state.isOpen = !state.isOpen
    },
  },
})

// Action creators are generated for each case reducer function
export const { menuChangeState } = menuSlice.actions

export default menuSlice.reducer
