import { useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin()
gsap.registerPlugin(useGSAP)
gsap.registerPlugin(ScrollTrigger)

const Academy = () => {
  const academy = useRef()
  const strategy = useRef()
  const brand = useRef()
  const outgoing = useRef()
  const execution = useRef()

  useGSAP(
    () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.main-copy',
          start: 'start 70%',
          // markers: true,
        },
      })

      tl.from('.main-copy', {
        y: 100,
        duration: 1,
        autoAlpha: 0,
      })
      tl.from('.main-copy-icon', {
        duration: 1,
        autoAlpha: 0,
      })
    },
    { scope: academy }
  )

  useGSAP(
    () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.strategy-title',
          start: 'start 70%',
        },
      })
      tl.from('.strategy-title', {
        y: 100,
        duration: 1,
        autoAlpha: 0,
      })
      tl.from('.strategy-disc', {
        y: 100,
        duration: 1,
        autoAlpha: 0,
      })
      tl.from('.strategy-line', {
        width: 0,
        duration: 1,
        autoAlpha: 0,
      })
      tl.from('.strategy-img', {
        duration: 2,
        autoAlpha: 0,
      })
    },
    { scope: strategy }
  )

  useGSAP(
    () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.brand-title',
          start: 'start 70%',
        },
      })
      tl.from('.brand-title', {
        y: 100,
        duration: 1,
        autoAlpha: 0,
      })
      tl.from('.brand-disc', {
        y: 100,
        duration: 1,
        autoAlpha: 0,
      })
      tl.from('.brand-line', {
        width: 0,
        duration: 1,
        autoAlpha: 0,
        transformOrigin: 'left',
      })
      tl.from('.brand-img', {
        duration: 2,
        autoAlpha: 0,
      })
    },
    { scope: brand }
  )

  useGSAP(
    () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.outgoing-title',
          start: 'start 70%',
        },
      })
      tl.from('.outgoing-title', {
        y: 100,
        duration: 1,
        autoAlpha: 0,
      })
      tl.from('.outgoing-disc', {
        y: 100,
        duration: 1,
        autoAlpha: 0,
      })
      tl.from('.outgoing-line', {
        width: 0,
        duration: 1,
        autoAlpha: 0,
      })
      tl.from('.outgoing-img', {
        duration: 2,
        autoAlpha: 0,
      })
    },
    { scope: outgoing }
  )

  useGSAP(
    () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.academy-strategy',
          start: 'start 70%',
        },
      })
      tl.from('li', {
        y: 50,
        stagger: 0.3,
        duration: 0.5,
        autoAlpha: 0,
      })
    },
    { scope: execution }
  )

  return (
    <div ref={academy} className="w-full mb-14 lg:mb-28">
      <div className="w-full flex flex-col lg:flex-row h-[400px] lg:h-[500px] mb-10 lg:mb-28">
        <div className="order-1 lg:order-first basis-1/3 bg-pink-300 flex flex-col items-center justify-center">
          <h2 className="text-4xl lg:text-5xl xl:text-6xl font-bold text-white border-b border-white pb-2 mb-3 lg:mb-5 inline-block">
            815 Academy
          </h2>
          <h3 className="text-sm md:text-base lg:text-lg bg-white py-1 rounded-full w-36 lg:w-48 text-center">
            815 アカデミー
          </h3>
        </div>
        <div className="flex-1 bg-academy-image bg-cover bg-center"></div>
      </div>

      <h1 className="main-copy text-center text-2xl/relaxed lg:text-4xl/relaxed mb-10 lg:mb-20">
        女性が1人で、
        <br className="md:hidden" />
        またはスモールビジネスで創る
        <br />
        ビジネスを成功に導く
        <br className="md:hidden" />
        「815 アカデミー」
      </h1>
      <div className="main-copy-icon w-full flex justify-center mb-14 lg:mb-20">
        <img
          className="inline text-center w-20 md:w-24"
          src="/images/shapes.png"
          alt=""
        />
      </div>

      <div ref={strategy} className="container">
        <div className="flex flex-col md:flex-row items-center gap-7 lg:gap-20 mb-14 lg:mb-20">
          <div className="md:flex-1">
            <h4 className="strategy-title text-[1.35rem] lg:text-3xl/relaxed">
              女性ならではの「強み」を活かした
              <br />
              ビジネス戦略を考えます
            </h4>
            <div className="strategy-line bg-secondary h-0.5 rounded-full my-5 lg:my-10"></div>
            <p className="strategy-disc text-base lg:text-lg/relaxed">
              女性は「感受性が豊か」であり、「柔軟性」があります。さらに「高いコミュニケーションカ」を活かし、自分のスキルや技術を持って成果を上げるビジネス戦略を考えます。
            </p>
          </div>
          <div className="strategy-img w-72 md:basis-2/5">
            <img src="/images/academy_01.png" alt="" />
          </div>
        </div>

        <div
          ref={brand}
          className="flex flex-col md:flex-row items-center gap-7 lg:gap-20 mb-14 lg:mb-20"
        >
          <div className="brand-img order-last md:order-first w-72 md:basis-2/5">
            <img src="/images/academy_02.png" alt="" />
          </div>
          <div className="md:flex-1">
            <h4 className="brand-title text-[1.35rem] lg:text-3xl/relaxed">
              自分の「強み」と「価値」を知り
              <br />
              自分の「ブランド価値」を作ります
            </h4>
            <div className="brand-line bg-accent h-0.5 rounded-full my-5 lg:my-10"></div>
            <p className="brand-disc text-base lg:text-lg/relaxed">
              得意な分野、スキル、経験を誰に活かせるのか、自分の特徴や個性は何なのか、客観的な視点を持ち、自分の価値や魅力を知り、自身のビジネスを発展させる方法を、学びます。
            </p>
          </div>
        </div>

        <div
          ref={outgoing}
          className="flex flex-col md:flex-row items-center gap-7 lg:gap-20  mb-40"
        >
          <div className="md:flex-1">
            <h4 className="outgoing-title text-[1.35rem] lg:text-3xl/relaxed">
              セルフブランディングを計画し、
              <br />
              「自己発信」の方法を構築します
            </h4>
            <div className="outgoing-line bg-primary h-0.5 rounded-full my-5 lg:my-10"></div>
            <p className="outgoing-disc text-base lg:text-lg/relaxed">
              自分の価値を発言する方法を学び、顧客の頼を築くことで、自分のビジネスを広く知ってもらう戦略を立てます。
              <br />
              また、「365日お客様ファン化計画」で長く愛されるビジネスの実践方法を学びます。
            </p>
          </div>
          <div className="outgoing-img w-72 md:basis-2/5">
            <img src="/images/academy_03.png" alt="815アカデミー" />
          </div>
        </div>

        <div
          ref={execution}
          className="bg-pink-100 pt-8 pb-5 px-3 md:px-5 lg:p-10 rounded-lg"
        >
          <h4 className="text-[1.35rem] lg:text-3xl/relaxed text-center mb-7 lg:mb-10">
            女性のビジネスに
            <br />
            新たな戦略を提案し、実行する
          </h4>
          <ul className="academy-strategy grid grid-cols-2 md:grid-cols-3 gap-3 lg:grid-cols-5 lg:gap-5">
            <li>
              <h6>Vision</h6>
              <h5>ビジョン</h5>
              <span></span>
              <p>
                自分のビジネスは何を目指し、自分は何者になりたいのか、ビジョンを考える。
              </p>
            </li>
            <li>
              <h6>Marketing</h6>
              <h5>マーケティングカ</h5>
              <span></span>
              <p>
                自分のビジネスを提供する場所の特徴を掴み、顧客ニーズを知る。
              </p>
            </li>
            <li>
              <h6>Branding</h6>
              <h5>ブランディングカ</h5>
              <span></span>
              <p>自分ブランドの確立。個性と魅力をアピールする力を養う。</p>
            </li>
            <li>
              <h6>Story</h6>
              <h5>ストーリー作成</h5>
              <span></span>
              <p>
                自分のサービスが顧客に届くまでの道筋を描く。集客ストーリーをイメージできるようになる。
              </p>
            </li>
            <li>
              <h6>Business</h6>
              <h5>選ばれるビジネス</h5>
              <span></span>
              <p>
                すでにある顧客に最大のファンとなってもらい、内からビジネスを広げていく力をつけ
                る。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Academy
